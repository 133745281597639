import React from "react";
import Designs from "../containers/visuella/Designs";

const DesignsPage = () => {
  return (
    <>
      <Designs isLatest={false} />
    </>
  );
};
export default DesignsPage;
